@import './theme.scss';
html {
    @apply overflow-x-hidden;
}
body {
    font-family: 'Open Sans';
    // font-family: 'Lato';
    .app-container {
        .not-implemented {
            @apply hidden h-0 w-0;
        }
        @media only screen and (max-width: 768px) {
            & > div {
                @apply hidden h-0 w-0 overflow-hidden;
                &.not-implemented {
                    @apply absolute flex flex-col h-screen w-full overflow-hidden items-center justify-center p-8;
                    span {
                        @apply text-primary text-lg text-center;
                    } 
                    img {
                        max-width: 800px;
                        object-fit: cover;
                        @apply w-full mb-8;
                    }
                }
            }
        }
        transition: opacity 1s ease-in;
        grid-template-rows: auto 1fr;
        grid-template-columns: auto 1fr;
        grid-template-areas:
            'navbar app-content'
            'navbar app-content';
        .navbar {
            display: none;
            @apply bg-secondary;
        }
        .app-content {
            grid-area: app-content;
            .screen-content {
                @apply flex-1 overflow-hidden;
            }
            @apply flex flex-col;
        }
        @media only screen and (min-width: 640px) {
            .navbar {
                display: flex;
                grid-area: navbar;
            }
        }
        @apply h-screen w-full overflow-hidden grid;
    }
    @apply h-screen w-full text-white bg-secondary-thin overflow-x-hidden;
}
