$blue: #0a192f;
$blue-light: #112240;
$blue-blur: rgba(10, 25, 47, 0.85);

$primary: #64ffda;
$white: #ccd6f6;
$white-light: #8892b0;
$white-thin: #8892b036;

$card-shadow: #020c1bb3;
$card-border-radius: 4px;

$tab-height: 40px;
$tab-width: 130px;

// // sm
// @media only screen and (min-width: 	640px) {
// }
// // md
// @media only screen and (min-width: 	768px) {
// }
// // lg
// @media only screen and (min-width: 1024px) {
// }
// // xl
// @media only screen and (min-width: 1280px) {
// }
// // 2xl
// @media only screen and (min-width: 1536px) {
// }
